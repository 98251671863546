import { useEffect, useState } from "react";

function App() {

  const [counter, setCounter] = useState(6);

  useEffect(() => {
    setTimeout(() => {
      if(counter > 1) setCounter(counter -1);
    }, 1000);

    if(counter == 1){
      window.location.assign('https://gaper.io')
    }

  },[counter])

  return (
    <div className='flex justify-center items-center'>
      <div className='w-full md:w-[1020px] xl:w-[1220px] 2xl:w-[1420px] self-center text-center font-normal '>
        {/* First heading */}
        <div className="border-b border-l border-r border-[#D6D6D6] py-3 md:py-5 text-[#747474] text-xs md:text-[32px] mx-2 md:mx-0">
            Madera is now associated with its parent brand Gaper.io
        </div>

        {/* Second heading */}
        <div className="hidden md:block text-[80px] font-medium pt-16">Redirecting to Gaper.io website</div>
        {/* For mobile screens */}
        <div className="text-[32px] md:hidden font-medium pt-20 mx-2 md:mx-0">Redirecting to <br></br> Gaper.io website</div>

        {/* Counter */}
        <div className="text-[72px] font-medium -mt-3">0{counter}</div>

        {/* Text */}
        <div className="text-base md:text-[32px] mb-16 mx-2 md:mx-0 leading-snug">Hire Top 1% Software Engineers for <br></br>your Startup in next 24 hours</div>

        {/* <div className="h-80"></div> */}

        {/* Image */}
        <div className="justify-center hidden md:flex justify-end">
          {/* Mac image */}
          <div className=""><img src="macbook.svg" className="w-[900px] ml-1"/></div>
          <div className="text-2xl mt-[14rem] ml-20 text-[#747474]">
            <div className="px-5 py-2 rounded-[30px] border border-[#747474]">Come Along</div> 
            <div><img src="curl.png" className="pt-5 ml-20"/></div>
          </div>
        </div>

        {/* for small screens */}
        <div className="md:hidden justify-center flex items-end">
          <div>
            <div className="pr-10"><img src="macPhone.png" className=""/></div>
            <div className="text-2xl mt-5 text-[#747474] justify-center flex ">
              <div>
                <div><img src="curl.png" className="pt-5 ml-20"/></div>
                <div className="px-5 py-2 my-5 rounded-[30px] border border-[#747474] w-52">Come Along</div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
